import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { APPLICATION_FORM_ID } from '../../theme/constants';
import { OpenPositionType } from '../../utils/careersTypes';
import BlogBackButton from '../BlogBackButton';
import Button, { ButtonLinkType } from '../Button';
import Text from '../Text';
import './style.scss';

export interface OpenPositionHeaderProps
  extends Pick<OpenPositionType, 'title' | 'conditions'> {
  className?: string;
}

const OpenPositionHeader: React.FC<OpenPositionHeaderProps> = ({
  className,
  title,
  conditions,
}) => {
  const { t } = useTranslation();

  return (
    <div className={`open-position-header ${className}`}>
      <BlogBackButton color="dark" href="/careers" buttonText={t('careers')} />
      <div className="position-info open-position-header__position-info">
        <div className="position-info__text-wrapper">
          <Text type="h1" weight="bold" className="position-info__title">
            {title}
          </Text>
          <Text type="p">{conditions}</Text>
        </div>
        <Button
          color="primary"
          size="small"
          className="position-info__button"
          href={`#${APPLICATION_FORM_ID}`}
          linkType={ButtonLinkType.ANCHOR}
        >
          {t('apply_button')}
        </Button>
      </div>
    </div>
  );
};

export default React.memo(OpenPositionHeader);
