import classNames from 'classnames';
import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React, { useCallback } from 'react';
import ChevronLeft from '../icons/ChevronLeft';
import Text from '../Text';
import './style.scss';

type BlogBackButtonProps = {
  className?: string;
  href?: string;
  color?: 'dark' | 'light';
  buttonText?: string;
};

const BlogBackButton: React.FC<BlogBackButtonProps> = ({
  href,
  className,
  color,
  buttonText,
}) => {
  const { t } = useTranslation();

  const onGoBack = useCallback(() => {
    const prevPath: string = (window as any).previousPath;

    if (!href) {
      navigate(-1);
      return;
    }

    prevPath.endsWith(href) ? navigate(-1) : navigate(href);
  }, [href]);

  return (
    <div className={`back-button ${className}`}>
      <button
        className={classNames('back-button__link', {
          'back-button__link_light': color === 'light',
        })}
        onClick={onGoBack}
      >
        <ChevronLeft
          className="back-button__chevron"
          pathClassName={classNames({ chevron__path_light: color === 'light' })}
        />
        <Text
          color="dark"
          weight="bold"
          type="p"
          className={classNames('back-button__text', {
            'back-button__text_light': color === 'light',
          })}
        >
          {buttonText ?? t('blog').toUpperCase()}
        </Text>
      </button>
    </div>
  );
};

export default React.memo(BlogBackButton);
