import { HeadProps, PageProps, graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import React from 'react';
import { APPLICATION_FORM_ID } from '../../theme/constants';
import { CareersPageType, OpenPositionType } from '../../utils/careersTypes';
import Footer from '../Footer';
import JobForm from '../JobForm';
import Markdown from '../Markdown';
import MasonryBlock from '../MasonryBlock';
import Navigation from '../Navigation';
import OpenPositionHeader from '../OpenPositionHeader';
import { SourceOption } from '../Picker';
import RecruitmentSteps from '../RecruitmentSteps';
import Text from '../Text';
import SEO from '../seo';
import './style.scss';

type DataProps = {
  sanityOpenPosition: OpenPositionType;
  allSanityOpenPosition: {
    nodes: { slug: { current: string }; title: string }[];
  };
  sanityCareersPage: CareersPageType;
};

const OpenPositionPage: React.FC<PageProps<DataProps>> = ({
  data: {
    sanityOpenPosition: {
      title,
      conditions,
      slug,
      body,
      recruitmentStep,
    },
    allSanityOpenPosition: { nodes },
    sanityCareersPage: { teamMedia },
  },
}) => {
  const formPositions = nodes.map<SourceOption>((node) => ({
    value: node.slug.current,
    body: node.title,
  }));

  const { t } = useTranslation();

  return (
    <div>
      <Navigation headerBackgroundColor="light" />
      <OpenPositionHeader title={title} conditions={conditions} />

      <div className="open-position-content">
        <Markdown source={body} className="open-position-content__text" />

        <Text weight="bold" type="h4" className="careers-page__header">
          {t('team_media_title')}
        </Text>
        <MasonryBlock
          className="open-position-content__item"
          height={4}
          width={4}
          masonryItems={teamMedia}
        />

        <Text weight="bold" type="h4" className="open-position-content__header">
          {t('recruitment_steps_title')}
        </Text>
        <RecruitmentSteps
          steps={recruitmentStep}
          className="open-position-content__item"
        />
      </div>

      <div className="job-form-wrapper" id={APPLICATION_FORM_ID}>
        <JobForm
          positions={formPositions}
          initialPositionValue={slug.current}
        />
      </div>

      <Footer noFooterCard />
    </div>
  );
};

export const query = graphql`
  query OpenPosition($id: String!, $language: String!) {
    sanityCareersPage {
      teamMedia {
        altText
        videoUrl
        colspan
        rowspan
        image {
          asset {
            gatsbyImageData(
              height: 400
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    }
    allSanityOpenPosition {
      nodes {
        slug {
          current
        }
        title
      }
    }
    sanityOpenPosition(id: { eq: $id }) {
      id
      title
      description
      slug {
        current
      }
      conditions
      body
      recruitmentStep {
        title
        body
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = ({
  data: {
    sanityOpenPosition: { title, description },
  },
}: HeadProps<DataProps>) => (
  <SEO
    title={`${title} | Stormotion`}
    description={
      description ?? `Stormotion is seeking a ${title}. Join our team!`
    }
  />
);

export default React.memo(OpenPositionPage);
